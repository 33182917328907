import {templateOne} from '../templates/template-one'
import {templateTwo} from '../templates/template-two'
import {templateThree} from '../templates/template-three'
import {templateFour} from '../templates/template-four'

function minify( s ){
  return s
    .replace(/>[\r\n ]+</g, "><")
    .replace(/(<.*?>)|\s+/g, (m, $1) => $1 ? $1 : ' ')
    .trim()
}

export function getSource(signature, template, social) {
  const templates = {
    templateOne,
    templateTwo,
    templateThree,
    templateFour
  }

  const tempSig = {
    name: 'Nombre Apellido',
    job: 'Puesto o cargo ocupado',
    company: 'Empresa.',
    address: 'Mi dirección o lugar de trabajo',
    email: 'micorreo@servidor.com',
    phone: '+ 123 456 789',
    website: 'http://www.miweb.com',
    linkText: 'Genera una firma para tu e-mail. Gratis en:',
    linkUrl: 'https://firmas.heroegeek.com/',
    disclaimer: `El servicio es totalmente gratuito y puedes hacer uso del mismo sin ninguna restricción. `,
  }

  const tempSocial = {
    facebook: 'https://www.facebook.com/',
    linkedin: 'https://www.linkedin.com/',
    twitter: 'https://twitter.com/',
    instagram: 'https://instagram.com/',
  }
  
  let details = (Object.entries(signature).length === 0) ? tempSig : signature;
  let networks = (Object.entries(signature).length === 0) ? tempSocial : social;
  
  return minify(templates[template](details, networks));
}