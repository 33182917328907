import React from 'react'
import { Form, FormGroup, Label, Input, FormText } from 'reactstrap'
import {getValue} from '../../utils/helpers'

export function GeneralForm({dispatch, signature}) {
  const fields = [
    {
      label: 'Imagen',
      name: 'logo',
      placeholder: 'https://servidordeimagen.com/mifotoguapa.png'
    },
    {
      label: 'Nombre completo',
      name: 'name',
      placeholder: 'Juanito Geek'
    },
    {
      label: 'Cargo o puesto',
      name: 'job',
      placeholder: 'Creador de bugs'
    },
    {
      label: 'Empresa',
      name: 'company',
      placeholder: 'Héroe Geek'
    },
    {
      label: 'Email',
      name: 'email',
      placeholder: 'micorreo@servidor.com'
    },
    {
      label: 'Dirección',
      name: 'address',
      placeholder: 'Mi dirección o lugar de trabajo'
    },
    {
      label: 'Teléfono',
      name: 'phone',
      placeholder: '+ 123 456 789'
    },
    {
      label: 'Website',
      name: 'website',
      placeholder: 'https://www.miweb.com'
    },
  ]

  return (
    <>
      <h3 className='mb-4'>Detalles de la firma</h3>
      <Form>
        {fields.map(({name, label, placeholder}, index) => (
          <FormGroup key={index}>
            <Label for={name}>{label}</Label>
            <Input
              type='text'
              name={name}
              id={name}
              onChange={(event) => dispatch({type: 'signature', target: event.target})}
              value={getValue(signature[name])}
              placeholder={placeholder}
            />
            {
              name === 'logo' && <FormText>Puedes alojar tu imagen en algún servidor público como Drive</FormText>
            }
          </FormGroup>
        ))}
      </Form>
    </>
  )
}