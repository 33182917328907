import React from 'react'


export default function Header() {
  return (
    <header className='page-header text-light d-flex'>
     <div className="brand border-right border-dark">
     <img src={require('..//img/logo64.png')}/>
      </div>
      <div className='d-none d-md-flex justify-content-between flex-grow-1 align-items-center px-4'>
        <h1 className=''>Genera gratis tu firma para tu e-mail</h1>
      </div>
    </header>
  )
}